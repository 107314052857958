@import '../../../styles/variables';

.container {
  min-height: 90vh;
  background: var(--tealLight);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    min-height: 10rem;
    padding: 8rem 0 6rem;
    width: 100%;

    .illustrationContainer {
      position: relative;
      margin: -3rem auto -1rem;

      .stepMainIllustration {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        opacity: 0;
        transition: opacity 300ms ease;
      }
    }

    .temperatureIndicator {
      position: absolute;
      z-index: 2;
      left: 20%;
    }

    .timeIndicator {
      position: absolute;
      width: 4rem;
      right: 20%;
      transform: translateY(-0.5rem);
      z-index: 5;
    }

    .warningIconContainer {
      position: absolute;
      right: 20%;
      transform: translateY(-150%);
    }
  }

  .afterContent {
    position: relative;
    margin-top: 7rem;

    a.allRecipesLink {
      @include heading-m;
      display: block;
      line-height: 1;
      width: max-content;
      margin: 1rem auto;
      padding: 1rem 1.5rem;
      border-radius: 15% 1rem 20% 0.5rem;
      background: var(--blueLight);
      color: var(--white);
    }

    .similarRecipes {
      padding: 0 1.5rem;
      margin-top: 3rem;
      margin-bottom: 3rem;

      .similarRecipesHeader {
        @include heading-m;
        line-height: 1.1;
      }
    }
  }
}

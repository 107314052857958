@import '../../../styles/variables';

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  min-height: 100vh;

  background: var(--whiteEgg);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    .characters {
      display: flex;
      align-items: baseline;
      margin-bottom: 1rem;
    }

    .text {
      @include heading-l;
      color: var(--blueDark);
    }
  }
}

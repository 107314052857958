.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.5rem;

  .category {
    background: var(--blueLight);
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff;
    padding: 0.2rem 1rem;
    border-radius: 0.3rem;

    &.active {
      background: var(--turquoise);
    }
  }
}

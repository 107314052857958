.container {
  color: var(--blueLight);

  .inputField {
    background: #e6e2dd;
    border: none;
    border-radius: 0.6rem;
    width: 100%;
    padding: 0.2rem 2.5rem 0.2rem 2.8rem;
    font-family: inherit;
    font-size: 1.625rem;
  }

  .resetSearchButton {
    position: absolute;
    transform: translate(-140%, 40%);
    width: 1.5rem;
    height: 1.5rem;
  }

  .searchIcon {
    position: absolute;
    transform: translate(45%, 30%);
    pointer-events: none;
  }
}

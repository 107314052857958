@import '../../../styles/variables';

.container {
  padding: 1rem;
  max-width: 30rem;
  min-height: 100vh;
  margin: 0 auto;
  background: var(--tealLight);
  border-radius: 1rem;

  .header {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .characterComment {
    display: flex;
    align-items: center;
    margin: 0 1rem;

    .textContainer {
      @include heading-m;
      line-height: 1;
      background: white;
      padding: 1rem;
      border-radius: 1rem;
      margin: 0.5rem 1.5rem;
      position: relative;
      z-index: 1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 2rem;
        height: 1.5rem;
        background: white;
      }
    }

    &.evneComment {
      .textContainer {
        color: #8c0858;

        &:before {
          transform: rotate(-45deg) skew(45deg);
          left: 0;
        }
      }
    }

    &.iverComment {
      flex-direction: row-reverse;

      .textContainer {
        color: var(--violetDark);

        &:before {
          background: white;
          transform: translate(0, 0.5rem) skew(45deg);
          right: 0;
        }
      }
    }
  }

  .tipsContainer {
    .loading {
      @include heading-m;
      min-height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tip {
      background: #fffff8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      border-radius: 5% 10% 8% 3%;

      margin: 1rem;
      padding: 1rem;
      display: flex;
      align-items: center;

      .text {
        line-height: 1.1;
        color: var(--blueLight);
        margin: 0.5rem;
      }

      .illustration {
        max-width: 8rem;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
  }

  a.homeLink {
    @include heading-m;
    display: block;
    line-height: 1;
    width: max-content;
    margin-bottom: 3rem;
    margin-left: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 15% 1rem 20% 0.5rem;
    background: var(--blueLight);
    color: var(--white);
  }
}

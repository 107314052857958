@import '../../../styles/variables';

.container {
  .header {
    position: fixed;
    z-index: 999;
    top: 1rem;

    .linkToMain {
      background: var(--recipeColorMain);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      height: 6rem;
      border-radius: 48% 50% 50% 51%;
      border: 0.45rem solid var(--whiteEgg);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

      img {
        opacity: 0;
        transition: opacity 300ms ease;
      }

      .mainAnimationContainer {
        width: 4.375rem;
        height: 4.375rem;
      }
    }

    .stepCounter {
      @include heading-s;
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      background: var(--turquoise);
      color: #fff;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, 0);
      line-height: 1;
      padding: 0.5rem 0.5rem;
      border-radius: 50%;

      .currentNumber {
        @include heading-m;
      }
    }
  }

  .descriptionContainer {
    position: fixed;
    z-index: 999;
    color: var(--blueLight);
    background: var(--whiteEgg);

    padding: 1.5rem 0;
    padding-left: 2rem;
    padding-right: 2.5rem;
    width: 90%;
    max-width: 27rem;

    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    bottom: 2rem;
    border-radius: 10% 1rem 10% 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    font-size: 1.8rem;
    line-height: 1;
  }

  .buttonsContainer {
    position: fixed;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .scrollButton {
    background: var(--turquoise);
    border-radius: 3.5rem 2.7rem 3.5rem 3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

    &.upButton {
      transform: scale(0.7);
      padding: 1.3rem 0.5rem 0.8rem;
    }
    &.downButton {
      margin-left: auto;
      padding: 1.4rem 0.4rem 0.7rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 1.3rem 34% 26% 38%;
    }
  }
}

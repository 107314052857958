@import '../../styles/variables';

.container {
  background: #fffc;
  padding: 0.4rem;
  width: 100%;
  min-width: 7rem;
  max-width: 8.5rem;
  height: 10rem;
  border-radius: 0.2rem;
  box-shadow: 2px 3px 3px -2px #00000030;
  position: relative;

  .title {
    color: var(--white);
    background: var(--ingredientColor, var(--greyDark));
    text-align: center;
    border-radius: 8%;
    font-size: 1.375rem;
    padding: 0.3rem 0.2rem;
    line-height: 0.8;
    position: relative;
    z-index: 5;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    transition: opacity 300ms ease;
    z-index: 10;
    max-width: 85%;
  }

  .swirlIcon {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  .unitsContainer {
    position: absolute;
    bottom: 0.3rem;
    width: calc(100% - (0.4rem * 2));
    display: flex;
    justify-content: space-between;
    z-index: 15;

    .unitIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem;
      background: var(--blueLight);
      border-radius: 25%;

      img {
        opacity: 0;
        transition: opacity 300ms ease;
      }
    }

    .amountField {
      background: var(--blueLight);
      color: var(--white);
      width: max-content;
      padding: 0rem 0.3rem;
      border-radius: 30%;

      .amount {
        font-size: 1.5rem;
        padding-right: 0.15rem;
      }
      .unit {
        position: relative;
        top: 0.1rem;
        font-size: 1rem;
      }
    }
  }
}

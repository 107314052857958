@import '../../../styles/variables';

.container {
  position: absolute;
  opacity: 0;
  transition: opacity 300ms ease;

  &.isLoaded {
    opacity: 1;
  }
}

@import '../../../styles/variables';

.container {
  width: 5rem;
  position: relative;

  .text {
    @include heading-l;
    color: var(--blueDark);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);

    &.degrees {
      font-size: 1rem;
      top: 47%;
    }
  }
}

@import '../../styles/variables';

.container {
  max-width: 30rem;
  margin: 0 auto;
  position: relative;
  width: 100%;

  .content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .header {
      background: var(--recipeColorMain, grey);
      padding: 1rem;
      border-radius: 0 0 10% 4%;

      .headerMain {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .text {
          color: var(--recipeTextColor, #ffffff);
          line-height: 1;

          a.linkBack {
            background: #00000044;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 2.5rem 3rem 2rem 3rem;
            display: block;
            width: max-content;
            padding: 0.8rem;
            margin-bottom: 1rem;
          }

          .subtitle {
            @include heading-s;
          }
          .title {
            @include heading-l;
          }
          .amount {
            display: block;
            line-height: 1.2;
          }
        }
      }

      .mainAnimationContainer {
        width: 11.25rem;
        height: 11.25rem;
        max-width: 30%;
      }

      .mainIllustrationImage {
        max-width: 30%;
        opacity: 0;
        transition: opacity 300ms ease;
      }

      .headerButtons {
        display: flex;
        justify-content: space-between;
        color: #ffffff;

        a {
          display: block;
          line-height: 1;
          padding: 0.5rem 1rem;
          border-radius: 15% 1rem 20% 0.5rem;
        }

        .changeAmount {
          background: var(--blueLight);
        }

        .tips {
          background: var(--teal);
        }
      }
    }

    .body {
      .linkCards {
        margin: 1rem 0 2rem;

        a {
          @include heading-m;
          position: relative;
          background: var(--recipeColorMain);
          color: var(--blackLight);
          margin: 0.7rem 0.5rem;
          line-height: 1;
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          border-radius: 0.9rem 10% 8% 15%;

          &.tools {
            background: #54545444;
          }
          &.ingredients {
            background: #04616844;
          }
          &.steps {
            background: #f24b9d44;
          }

          .cardNumber {
            @include font-standard;
            font-size: 4rem;
            padding-left: 1rem;
            padding-right: 0.5rem;
          }

          .cardText {
            max-width: 50%;
          }

          .cardIcon {
            max-width: 30%;
            position: absolute;
            right: 0;
            opacity: 0;
            transition: opacity 300ms ease;

            &.arrow {
              display: block;
              background: var(--teal);
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
              border-radius: 2.5rem 3rem 2rem 3rem;
              width: max-content;
              padding: 0.8rem;
              margin-right: 1rem;
              opacity: 1;
            }
          }
        }
      }
    }

    .breakBar {
      height: 10rem;
      background: var(--recipeColorMain);
      width: 100%;
      border-radius: 5% 15% 10% 4rem;

      .breakIcon {
        margin: 0 auto;
        height: 118%;
        margin-top: -1.5rem;
        padding: 0 1.5rem;
        opacity: 0;
        transition: opacity 300ms ease;
      }
    }

    .similarRecipes {
      padding: 0 1rem;
      margin-top: 2rem;
      margin-bottom: 3rem;

      .similarRecipesHeader {
        @include heading-m;
        line-height: 1.1;
      }
    }
  }
}

@import '../../styles/variables';

.mainContainer {
  padding: 1rem;
  margin: 0 auto;
  position: relative;
  max-width: 30rem;
  min-height: 100vh;
  padding-top: 1rem;

  a.linkBack {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;

    .arrowIconContainer {
      background: var(--blueLight);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 2.5rem 3rem 2rem 3rem;
      width: max-content;
      padding: 0.8rem;
      margin-right: 1rem;
    }

    .linkBackText {
      @include heading-m;
      color: var(--blueLight);
    }
  }

  .header {
    @include heading-m;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    padding: 0 1rem;
    text-align: center;
  }

  .sectionHeader {
    @include heading-m;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .text {
    text-align: center;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    padding: 0 1rem;
  }

  .linksContainer {
    margin: 1rem 0 5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    a,
    button {
      display: block;
      @include heading-m;
      text-align: center;
      padding: 1rem 1.5rem;
      margin: 0.5rem;
      border-radius: 15% 1rem 20% 0.5rem;
      color: var(--white);

      &.membership {
        background: var(--redLight);
      }

      &.login {
        background: var(--blueLight);
      }
    }
  }

  .otherRecipesContainer {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    padding: 0 1rem;

    opacity: 0;
    animation: fadeIn 300ms ease forwards;
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  }
}

@import '../../../styles/variables';

.container {
  padding: 1rem;
  max-width: 30rem;
  min-height: 100vh;
  margin: 0 auto;
  background: var(--tealLight);
  border-radius: 1rem;

  a.linkBack {
    background: var(--blueLight);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2.5rem 3rem 2rem 3rem;
    display: block;
    width: max-content;
    padding: 0.8rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      @include heading-l;
      line-height: 1;
    }
    .illustration {
      max-width: 25%;
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }

  .textContainer {
    margin-bottom: 1rem;

    .textHeader {
      @include heading-m;
      margin: 3rem 0 1rem;
    }

    p {
      line-height: 1.2;
      margin-bottom: 1rem;
    }
  }

  .characterComment {
    display: flex;
    align-items: center;
    margin: 0 1rem;

    .commentText {
      @include heading-m;
      line-height: 1;
      background: white;
      padding: 1rem;
      border-radius: 1rem;
      margin: 0.5rem 1.5rem;
      position: relative;
      z-index: 1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 2rem;
        height: 1.5rem;
        background: white;
      }
    }

    &.evneComment {
      flex-direction: row-reverse;

      .commentText {
        color: #8c0858;

        &:before {
          transform: translate(0, 0.5rem) skew(45deg);
          right: 0;
        }
      }
    }

    &.iverComment {
      .commentText {
        color: var(--violetDark);

        &:before {
          transform: rotate(-45deg) skew(45deg);
          left: 0;
        }
      }
    }
  }

  a.homeLink {
    @include heading-m;
    display: block;
    line-height: 1;
    width: max-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 15% 1rem 20% 0.5rem;
    background: var(--blueLight);
    color: var(--white);
  }
}

@import '../../../styles/variables';

.container {
  padding: 1rem;
  max-width: 30rem;
  min-height: 100vh;
  margin: 0 auto;
  background: var(--tealLight);
  border-radius: 1rem;

  a.linkBack {
    background: var(--blueLight);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2.5rem 3rem 2rem 3rem;
    display: block;
    width: max-content;
    padding: 0.8rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      @include heading-l;
      line-height: 1;
    }

    .mainIllustrationImage {
      max-width: 25%;
      opacity: 0;
      transition: opacity 300ms ease;
    }

    .mainAnimationContainer {
      width: 6.875rem;
      height: 6.875rem;
      max-width: 25%;
    }
  }

  .ingredients {
    margin: 1rem 0;
  }

  a.linkCard {
    @include heading-m;
    position: relative;
    color: var(--blackLight);
    line-height: 1;
    display: flex;
    align-items: center;
    border-radius: 0.9rem 10% 8% 15%;
    background: #54545444;
    padding: 0.5rem 0;
    margin: 1rem 0 2rem;

    .cardNumber {
      @include font-standard;
      font-size: 4rem;
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
    .cardText {
      max-width: 55%;
    }
    .cardIcon {
      max-width: 30%;
      position: absolute;
      right: 10px;
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }
}

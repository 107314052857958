.container {
  opacity: 0;
  transition: opacity 300ms ease;
  height: 100%;

  > div {
    height: 100%;
  }

  &.isLoaded {
    opacity: 1;
  }
}

@import '../../styles/variables';

.container {
  position: relative;
  background: #231a3c;
  color: #fff;
  min-height: 100%;
  overflow-x: hidden;

  .cloudsBackground {
    position: absolute;
    min-width: 50rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;

    .header {
      position: relative;
    }

    .main {
      max-width: 50rem;
      text-align: center;
      padding: 3rem 1rem 1rem;

      .title {
        @include heading-l;
        line-height: 1;
        font-size: 2.25rem;
      }

      ul.sellingPoints {
        margin: 1.5rem 0 2rem;
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 1.5rem;
          position: relative;
          font-size: 1.5rem;
          line-height: 1.1;

          &:not(:last-child)::after {
            content: '';
            width: 0.9rem;
            height: 0.8rem;
            background: #dabec5;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 130%);
            border-radius: 50% 30% 35% 36%;
          }
        }
      }

      .logInButton {
        @include heading-m;
        display: block;
        background: #90bd71;
        color: #502c59;
        padding: 0.5rem 1rem;
        margin: 1rem auto 3rem;
        position: relative;
        font-size: 1.125rem;
        border-radius: 15% 0.6rem 20% 0.2rem;

        transition: 50ms ease;
        &:hover,
        &:focus {
          transform: scale(1.05);
        }
      }

      .subscriptionLinks {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        a {
          margin: 0 0.5rem;
          transition: 100ms ease;

          &:nth-child(2) {
            padding-left: 1rem;
          }

          &:hover,
          &:focus {
            transform: scale(1.05);
          }
        }
      }
    }

    .appPreviewContainer {
      min-width: 30rem;
      max-width: 50rem;
      padding-bottom: 1rem;
      margin-bottom: 4rem;

      .appPreviewTitle {
        @include heading-m;
        text-align: center;
        margin-bottom: 2rem;
      }

      .appPreviewImage {
        object-fit: contain;
        object-position: 50% top;
      }
    }
  }
}

@import '../../../styles/variables';

.container {
  padding: 1rem;
  max-width: 30rem;
  margin: 0 auto;
  background: var(--tealLight);
  border-radius: 1rem;
  min-height: 100vh;

  .loading {
    text-align: center;
    margin: 5rem auto;
  }

  .notFound {
    @include heading-m;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

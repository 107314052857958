@import '../../styles/variables';

.container {
  background: var(--card-background-color, grey);
  display: block;
  margin: 1rem auto;
  aspect-ratio: 1 / 1;
  border-radius: 0.625rem;
  color: var(--card-text-color, #ffffff);
  text-align: left;
  overflow: hidden;
  padding: 2rem 1rem 1rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;

  .icon {
    width: 100%;
    height: 80%;
    margin-top: 20%;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  .mainAnimationContainer {
    width: 100%;
    height: 80%;
    margin-top: 20%;
  }

  .textContainer {
    position: absolute;
    max-width: 83%;

    .subTitle {
      @include heading-s;
      margin-bottom: -0.2rem;
    }

    .title {
      @include heading-m;
      line-height: 1.2;

      .lockIcon {
        display: inline-block;
        height: 1.3rem;
      }
    }

    .tryFree {
      @include font-bold;
      font-size: 1rem;
      color: var(--violetDark);
      background: #ffffff;
      display: block;
      width: max-content;
      padding: 0.1rem 0.5rem;
      margin-top: 0.5rem;
      border-radius: 18% 13% 2% 10%;
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  .featured {
    background: #ff670b;
    position: absolute;
    right: 0;
    top: 0;
    width: 15rem;
    height: 14rem;
    transform: rotate(45deg) translate(50%, -70%);
    transform-origin: top right;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.5rem;

    .featuredIcon {
      display: block;
      transform: rotate(-45deg);
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }
}

@import '../../../styles/variables';

.container {
  position: relative;
  margin-bottom: 8rem;

  .extraInfoItem {
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    margin-bottom: 1.5rem;

    .iconContainer {
      width: 4.5rem;
      height: 4.5rem;
      display: flex;
      align-items: center;
    }

    .textContainer {
      line-height: 1;
      background: #ffffff;
      padding: 1rem;
      border-radius: 1rem;
      position: relative;
      flex-shrink: 100;
      z-index: 1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 2rem;
        height: 1.5rem;
        background: #ffffff;
      }
    }

    &.evneComment {
      .textContainer {
        color: #8c0858;
        margin-left: 1.5rem;

        &:before {
          transform: rotate(-45deg) skew(45deg);
          left: 0;
        }
      }
    }

    &.iverComment {
      flex-direction: row-reverse;

      .textContainer {
        color: var(--violetDark);
        margin-right: 1.5rem;

        &:before {
          transform: translate(0, 0.5rem) skew(45deg);
          right: 0;
        }
      }
    }
  }
}

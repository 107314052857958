@import '../../../styles/variables';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 15.6rem;
  max-height: 15.6rem;

  .layer {
    position: absolute;
    opacity: 0;
    transition: opacity 300ms ease;
  }
}

@import '../../styles/variables';

.main {
  padding: 1rem;
  text-align: center;
  max-width: 25rem;
  margin: 0 auto;
  min-height: 100%;

  .menuButton {
    @include heading-m;
    display: block;
    margin-left: auto;
    color: var(--blueLight);
    font-size: 1.25rem;
  }

  .headerTitle {
    @include heading-m;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  .speechBubble {
    margin-top: 20px;
    background-color: #E6E1DC;
    border-radius: 3% 10% 15% 6%;
    padding: 10px;
    min-width: 19ch;
    max-width: 300px;

    // width: fit-content;

    display: grid;
    // grid-auto-columns: min-content;
    //grid-auto-columns: min-content;
    width: fit-content;

    .speechBubbleText {
      @include font-bold;
      font-size: 1.25rem;
      color: var(--blueLight);
      min-width: 19ch;

    }

    &::after {
      content: '';
      display: block;
      position: relative;
      margin-top: -40px;
      bottom: -30px;
      margin-left: auto;
      margin-right: auto;
      right: -30px;
      border-width: 30px 0 0 30px;
      border-style: solid;
      border-color: #E6E1DC transparent;
      width: 2px;
      border-radius: 0 0 40px 0;
      transform: translateY(10px);
    }

    .speechBubbleLink {
      @include font-bold;
      font-size: 1.25rem;
      text-align: left;
      display: block;
      background-color: var(--blueLight);
      width: fit-content;
      padding: 0.5rem 0.625rem;
      border-radius: 15% 1rem 20% 0.5rem;
      color: var(--white);
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;



      svg {
        margin-left: 2px;
        min-width: fit-content;
        width: auto;
      }
    }
  }

  .imageContainer {
    img {
      margin: 0 auto;
      max-height: 30rem;
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }

  .animationContainer {
    width: 100%;
    height: 100%;
    max-width: 17.5rem;
    max-height: 17.5rem;
    margin: 0 auto;
  }
}
@import '../../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--whiteEgg);
  overflow: auto;

  .content {
    max-width: 25rem;
    background: var(--yellow);
    margin: 0 auto;
    min-height: 100%;
    padding: 0.5rem 1rem;

    .topButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        @include heading-m;
        color: var(--blueLight);
        font-size: 1.25rem;

        &.logOutButton {
          background: var(--blueLight);
          color: #fff;
          padding: 0.5rem 1rem;
          border-radius: 15% 0.6rem 20% 0.4rem;
        }
      }
    }

    ul.navList {
      list-style-type: none;
      margin: 3rem 0 2rem;
      padding: 0;
      position: relative;

      li {
        @include heading-m;
        color: #000;
        font-size: 1.25rem;
        text-align: left;
        padding: 1.5rem 0;

        &::before {
          content: '';
          position: absolute;
          background: #000;
          width: 100%;
          height: 0.2rem;
          left: 0;
          top: 0;
        }

        &::after {
          content: '';
          position: absolute;
          background: #000;
          width: 100%;
          height: 0.2rem;
          left: 0;
          bottom: 0;
        }
      }
    }

    .card {
      border-radius: 3% 2% 2.5% 0.35rem;
      padding: 1rem;
      margin-bottom: 1rem;
      color: #000;

      .heading {
        @include heading-m;
        font-size: 1.25rem;
        line-height: 1.1;
        margin-bottom: 0.5rem;
      }

      .description {
        font-size: 1.25rem;
        line-height: 1.1;
      }

      &.gameAd {
        display: block;
        padding-bottom: 0;
        background: var(--whiteEgg);

        .gameAdImageContainer {
          img {
            margin: 0 auto;
            object-fit: contain;
            object-position: center top;
          }
        }
      }

      &.feedback {
        background: var(--yellowDark);

        .icons {
          display: flex;
          justify-content: center;
          margin-bottom: 0.5rem;
        }

        a.emailLink {
          text-decoration: underline;
          display: block;
          margin-top: 1rem;
        }
      }

      img {
        opacity: 0;
        transition: opacity 200ms ease;
      }
    }

    .socialMedia {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        margin: 0.5rem;
      }
    }
  }
}

@import 'variables';

@font-face {
  font-family: 'SD Sans Fill One';
  src: url('/fonts/SDSans-FillOne.woff2') format('woff2'),
    url('/fonts/SDSans-FillOne.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SD Sans Condensed';
  src: url('/fonts/SDSans-Condensed.woff2') format('woff2'),
    url('/fonts/SDSans-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#root,
#__next {
  isolation: isolate;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

// COLORS
:root {
  --black: #000000;
  --white: #ffffff;

  --yellow: #ffad14;
  --yellowDark: #e98c00;
  --orange: #e83b1c;
  --brown: #893613;
  --red: #b60a0a;
  --pink: #f14b9d;
  --violet: #8c0858;
  --redLight: #e01a54;
  --green: #768200;
  --greenDark: #1c6c0e;
  --violetBlue: #4a139a;
  --violetDark: #5b2b87;
  --blueLight: #1d578b;
  --blueMedium: #005090;
  --blueDark: #17356e;
  --teal: #046167;
  --tealLight: #e8f7f4;
  --turquoise: #06918d;
  --whiteEgg: #fbfdee;
  --beigeLight: #dbd0be;
  --greyDark: #515151;
  --blackLight: #321300;

  --background-light: #fff3e7;
}

html,
body {
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  @include text-default;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: var(--whiteEgg);
  transition: background 0.5s ease;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

@import '../../../styles/variables';

.container {
  .icon {
    width: 90%;
    margin: 0 auto;
  }

  .text {
    @include heading-s;
    background: var(--whiteEgg);
    color: var(--blackLight);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1;
    width: max-content;
    padding: 0.2rem 0.3rem;
    border-radius: 0.2rem;
  }
}

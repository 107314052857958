// FONTS
@mixin font-standard {
  font-family: 'SD Sans Condensed', sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-bold {
  font-family: 'SD Sans Fill One', sans-serif;
  font-weight: normal;
  font-style: normal;
}

// TEXT
@mixin text-default {
  @include font-standard;
  font-size: 1.25rem;
  color: var(--greyDark);
}

@mixin heading-l {
  @include font-bold;
  font-size: 2rem;
}

@mixin heading-m {
  @include font-bold;
  font-size: 1.375rem;
}

@mixin heading-s {
  @include font-bold;
  font-size: 0.875rem;
}

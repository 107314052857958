@import '../../../styles/variables';

a.link {
  background: var(--blueLight);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2.5rem 3rem 2rem 3rem;
  display: block;
  width: max-content;
  padding: 0.8rem;
}

@import '../../styles/variables';

.container {
  background: #fffc;
  padding: 0.4rem;
  width: 100%;
  min-width: 7rem;
  max-width: 8.5rem;
  height: 10rem;
  border-radius: 0.2rem;
  box-shadow: 2px 3px 3px -2px #00000030;
  position: relative;

  .title {
    color: var(--white);
    background: var(--greyDark);
    text-align: center;
    border-radius: 8%;
    font-size: 1.375rem;
    padding: 0.3rem 0.2rem;
    line-height: 0.8;
    position: relative;
    z-index: 5;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    transition: opacity 300ms ease;
    z-index: 10;
    max-width: 85%;
  }

  .swirlIcon {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  .warningIcon {
    position: absolute;
    bottom: 0.3rem;
    opacity: 0;
    transition: opacity 300ms ease;
    z-index: 12;
  }

  .quantityField {
    background: var(--greyDark);
    color: var(--white);
    width: max-content;
    padding: 0rem 0.3rem;
    position: absolute;
    bottom: 0.3rem;
    right: 0.5rem;
    border-radius: 30%;
    z-index: 15;

    .quantity {
      @include heading-m;
      line-height: 1.2;
    }
  }
}
